import React from "react";
import { Route, Routes } from "react-router-dom";
import { Cart, Menu, NotFound, Home, Order } from "./Pages";
import { Layout } from "./Components";
import { BrowserTracing } from "@sentry/tracing";
import * as Sentry from "@sentry/react";
import * as routes from "./routes";

try {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_URL,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
  });
} catch (error) {
  console.log("Ignore: Sentry init issue!");
}

function App() {
  return (
    <Routes>
      <Route path={routes.HOME} element={<Layout />}>
        <Route path={routes.HOME} element={<Home />} />
        <Route path={routes.MENU} element={<Menu />} />
        <Route path={routes.CART} element={<Cart />} />
        <Route path={routes.ORDER} element={<Order />} />
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default App;
