import React from "react";
import { Repeater } from "../../Components";
import {ItemCard} from "../../Components/Skeletons";

const MenuItem = () => {

    return (
        <div className="animate-pulse w-full mt-5">
            <div className="h-2 w-2/5 bg-slate-200 rounded mb-4"></div>
            <Repeater count={4}>
              <ItemCard></ItemCard>
            </Repeater>
        </div>
    );

};

export default MenuItem;