import React from "react";

const UserBadge = ({ FirstName, LastName, size=8 }) => {
    const getIntials = () => {
        let initials = ""
        if (FirstName) {
            initials = initials + FirstName[0]
        }
        if (LastName) {
            initials = initials + LastName[0]
        }
        return initials;
    }
    return (
        <div className={`flex items-center justify-center bg-gray-100 rounded-full  ring-2 ring-white-300 `}>
            <span className={`font-medium text-gray-700 p-${size}`}>
                {getIntials()}
            </span>
        </div>
    )
}
export default UserBadge;