import React, { useState, useEffect } from "react";
import { ButtonCounter, Spinner } from "../Components"
import { AiOutlineCloseCircle } from "react-icons/ai"
import { getCustomizationType } from "../Services/menuService";
import * as orderService from "../Services/orderService";
import * as utils from "../Services/utils";
import * as _ from 'lodash';

const MenuCard = ({ item, onItemAdded = {}, onItemRemoved = {} }) => {
  const [count, setCount] = useState(0);
  const [isCustomizationDisplayed, setIsCustomizationDisplayed] = useState(false);
  const [customizations, setCustomizations] = useState({});
  const [isCustomizationValid, setIsCustomizationValid] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const merchantId = utils.getMerchantId();

  const getOrderItemId = () => {
    return item.order.length > 0 ? item.order[0].id : null;
  }

  const setOrderItemId = (id) => {
    let order = {
      "id": id,
      "customizations": [],
      "quantity": 1,
    }
    item.order.push(order);
  }

  const deleteOrderItemId = (id) => {
    item.order = item.order.filter((orderItem) => {
      return id !== orderItem.id;
    });
  }

  const getOrderItemQuantity = () => {
    if (item.order.length > 0) {
      return _.sumBy(item.order, function (o) { return o.quantity; })
    }
    else { return 0 };
  }

  const validateCustomization = () => {
    /**
     * @description Check if required customization options are selected
     */

    let customizationIds = Object.keys(customizations);

    for (let i = 0; i < customizationIds.length; i++) {
      let customization = customizations[customizationIds[i]];
      let options = customization.options;
      let optionIds = Object.keys(options);
      let selectedCount = 0;

      for (let j = 0; j < optionIds.length; j++) {
        let option = options[optionIds[j]];
        if (option.isSelected) {
          selectedCount += 1;
        }
      };

      let isValid = customization.min_options <= selectedCount && selectedCount <= customization.max_options;
      if (!isValid) return false;
    }
    return true;
  }

  const getCustomizations = async (merchantId) => {
    /**
     * @descrption Get customizations from backend and transform
     * @param {String} merchantId - UUID of merchant
     */

    let response = await getCustomizationType(merchantId, item.id);
    let customizationTypes = [...response.data.results];
    let customizations = {};

    customizationTypes.forEach((customizationType) => {
      let options = [...customizationType.options];
      customizations[customizationType.id] = customizationType;
      customizations[customizationType.id].options = {};

      options.forEach((option) => {
        customizations[customizationType.id].options[option.id] = option;
        customizations[customizationType.id].options[option.id].isSelected = false;
      });
    });

    return customizations;
  }

  const openCustomizationPopup = async () => {
    /**
     * @description Open customization popup if Item has customization options
     */
    setCustomizations({})
    setIsCustomizationValid(true);
    setIsCustomizationDisplayed(true);
    let customizations = await getCustomizations(merchantId);
    setCustomizations(customizations);
  }

  const addToCartCustomizableItem = () => {
    /**
     * @description Add customizable item to cart if selected 
     * customization options are valid
     */

    let isValid = validateCustomization();
    setIsCustomizationValid(isValid);
    if (!isValid) return;

    let customizationOrderItem = getCustomizationMenuItem();
    if (count === 0 || !customizationOrderItem) {
      addItemToCart().then((response) => addCustomizationsToCart(response.data.id));
    } else {
      updateItemInCart(customizationOrderItem.id, customizationOrderItem.quantity + 1);
    }
    setIsCustomizationDisplayed(false);
    setCustomizations({});
  }

  const getCustomizationMenuItem = () => {
    let selectedCustomizations = getSelectedCustomizations();
    let matchedOrderedItem = item.order.filter((orderedItem) => {
      let orderedCustomizationItem = orderedItem.customizations.map((orderedCustomization) => {
        return { "customization_option": orderedCustomization.customization_option }
      });
      return _.isEqual(orderedCustomizationItem, selectedCustomizations);
    });
    return matchedOrderedItem.length > 0 ? matchedOrderedItem[0] : null;
  }

  const addNonCustomizableToCart = () => {
    count === 0
      ? addItemToCart()
      : updateItemInCart(getOrderItemId(), count + 1);
  }

  const updateItemInCart = (orderItemId, quantity) => {
    setIsLoading(true);
    orderService.updateItemQuantityToOrder(orderItemId, quantity).then((response) => {
      if (response.data) {
        item.order = item.order.map((orderedItem) => {
          if (orderedItem.id === response.data.id) {
            orderedItem.quantity = response.data.quantity;
          }
          return orderedItem;
        });
        setCount(getOrderItemQuantity());
      }
      setIsLoading(false);
    });
  }

  const removeFromCart = () => {
    const orderItemId = getOrderItemId();
    count > 1
      ? updateItemInCart(orderItemId, count - 1)
      : deteleItemFromCart(orderItemId);
  }

  const deteleItemFromCart = (orderItemId) => {
    setIsLoading(true);
    orderService.removeItemFromOrder(orderItemId).then((response) => {
      if (response.status === 204) {
        deleteOrderItemId(orderItemId);
        setCount(count - 1);
        onItemRemoved();
      }
      setIsLoading(false);
    })
  }

  const addItemToCart = async () => {
    setIsLoading(true);
    let params = {
      "menu_item": item.id,
      "quantity": 1
    }

    let orderId = utils.getOrderId();
    const response = await orderService.addItemToCart(merchantId, orderId, params);
    setCount(response.data.quantity);
    onItemAdded();
    setOrderItemId(response.data.id);
    setCount(count + 1);
    setIsLoading(false);
    return response;
  }

  const addCustomizationsToCart = (orderId) => {
    var selected_Customizations = getSelectedCustomizations();
    orderService.addCustomizations(orderId, selected_Customizations).then((response) => {
      item.order = item.order.map((orderedItem) => {
        if (orderedItem.id === orderId) {
          orderedItem.customizations = response.data.customizations;
        }
        return orderedItem;
      })
    });
  }

  const getSelectedCustomizations = () => {
    let selectedCustomizations = [];
    Object.keys(customizations).forEach((customizationTypeId) => {
      let customizationType = customizations[customizationTypeId];
      Object.keys(customizationType.options).forEach((optionId) => {
        let isSelected = customizationType.options[optionId].isSelected;
        if (isSelected) {
          let selectedCustomization = {
            "customization_option": optionId
          }
          selectedCustomizations.push(selectedCustomization);
        }
      })
    });
    return selectedCustomizations;
  }

  const changeRadioOption = (option) => {
    /**
     * @description Update radio buttons according to selected option
     * @param {Object} Customization option object
     */

    let tempCustomizations = { ...customizations };
    let customizationType = tempCustomizations[option.customization_type];
    let changedOption = customizationType.options[option.id];

    Object.keys(customizationType.options).forEach((optionId) => {
      if (option.id !== optionId) {
        customizationType.options[optionId].isSelected = false;
      }
    })
    changedOption.isSelected = true;
    setCustomizations(tempCustomizations);
  }

  const toggleCheckboxOption = (option) => {
    /**
     * @description Toggle option checkbox
     * @param {Object} option - Customization option object
     */
    let tempCustomizations = { ...customizations };
    let customizationType = tempCustomizations[option.customization_type];
    let changedOption = customizationType.options[option.id];
    changedOption.isSelected = !changedOption.isSelected;
    setCustomizations(tempCustomizations);
  }

  const customizationOptions = (options, minOptions, maxOptions) => {
    /**
     * @description Construct customization option's JSX
     * @param  {Object} options    - Options of customization type
     * @param  {Number} minOptions - Min options to be selected
     * @param  {Number} minOptions - Max options to be selected
     * @returns JSX element
     * */

    return Object.keys(options).map((optionId) => {
      let option = options[optionId];
      let isRadioButton = minOptions === 1 && maxOptions === 1
      let roundedStyle = isRadioButton ? 'rounded-full' : 'rounded-sm';

      return (
        <div key={option.id} className="flex justify-between p-2 text-sm text-gray-500">
          <p>{option.name}</p>
          <div className="flex ">
            {option.additional_price ? <p className="px-2">+ {option.additional_price}</p> : null}
            <input
              className={`h-5 w-5 bg-white border border-gray-300 ${roundedStyle} checked:bg-blue-600 cursor-pointer`}
              type={"checkbox"}
              name={option.customization_type}
              value={option.id}
              onChange={
                () => isRadioButton ? changeRadioOption(option) : toggleCheckboxOption(option)
              }
              checked={option.isSelected}
            />
          </div>
        </div>
      )
    })
  }

  const customizationTypes = () => {
    /**
    * @description: Construct customizations' JSX
    * @returns: JSX element
    * */

    if (Object.keys(customizations).length > 0) {
      return (
        <ul className="my-3 space-y-3">
          {
            Object.keys(customizations).map((customizationId) => {
              let customization = customizations[customizationId];
              return (
                <li key={customization.id}>
                  <div href="#" className="flex items-center p-3 text-sm text-gray-900 bg-gray-50">
                    <span className="flex-1 whitespace-nowrap">{customization.name}</span>
                    <span
                      className="inline-flex items-center justify-center px-2 py-0.5 ml-3 text-xs font-medium text-gray-500 bg-gray-200 rounded">
                      Minimum {customization.min_options}
                    </span>
                  </div>
                  <div>
                    {
                      customizationOptions(
                        customization.options,
                        customization.min_options,
                        customization.max_options,
                      )
                    }
                  </div>
                </li>
              )
            })
          }
        </ul>
      )
    } else {
      return (
        <div className="w-full flex justify-center py-6">
          <Spinner />
        </div>
      )
    }
  }

  useEffect(() => {
    let quantity = getOrderItemQuantity();
    setCount(quantity);
  }, []);

  return (
    <div>
      {/* Menu Item Card */}
      <div className="border-b-2 border-gray-100">
        <div className="bg-white max-w-lg rounded py-2">
          <div className="flex justify-between">
            <div className="flex flex-col justify-start items-start">
              <span className={`w-2 h-2 rounded-full ${item.is_veg ? 'bg-green-500' : 'bg-red-500'}`}></span>
              <span>{item.name}</span>
              <span>₹ {item.price}</span>
              <span className="text-sm text-gray-400 pt-1 pr-2 w-52 md:w-80 lg:w-80 break-words">
                {item.description}
              </span>
            </div>
            <div className="flex flex-col justify-end align-middle">
              {item.image ? <img src={item.image} alt={item.name} className="w-20 h-20 mb-1" /> : null}
              <ButtonCounter
                increamentHandler={item.has_customization ? openCustomizationPopup : addNonCustomizableToCart}
                decreamentHandler={item.has_customization ? () => { } : removeFromCart}
                count={count}
                isLoading={isLoading}
              />
              {item.has_customization ? <span className="text-xs text-center">Customizable</span> : null}
            </div>
          </div>
        </div>
      </div>

      {/* Customization Modal */}
      {isCustomizationDisplayed ?
        <div className="flex items-center justify-center overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal md:h-full bg-gray-500/40">
          <div className="w-full max-w-md h-full md:h-auto">

            {/* <!-- Modal content --> */}
            <div className="w-full fixed bottom-0 md:static bg-white rounded-t-lg">

              {/* <!-- Modal header --> */}
              <div className="flex justify-between py-4 px-6 rounded-t border-b">
                <h3 className="text-base font-semibold text-gray-900 lg:text-xl">
                  {item.name}
                  <p className="text-sm font-normal text-gray-500 pt-1">Select customization options</p>
                </h3>
                <div className="relative p-1.5 ml-auto inline-flex items-center">
                  <AiOutlineCloseCircle size={30} onClick={() => setIsCustomizationDisplayed(false)} />
                </div>
              </div>

              {/* <!-- Modal body --> */}
              <div className="px-4">
                {customizationTypes()}
              </div>

              {/* <!-- Modal Footer --> */}
              <div className="flex justify-between py-4 px-6 border-t">
                {
                  !isCustomizationValid ?
                    <p className="text-sm font-normal text-red-500 pt-2">Please select required options</p>
                    : <div></div>
                }
                <h3 className="text-base font-semibold text-gray-900">
                  <ButtonCounter
                    increamentHandler={addToCartCustomizableItem}
                    decreamentHandler={() => { }}
                    count={0} />
                </h3>
              </div>
            </div>
          </div>
        </div>
        : null}
    </div>
  );
};

export default MenuCard;
