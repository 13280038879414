import client from "./axiosConfig";
import * as utils from "./utils";

export const getOrder = (merchantId, orderId) => {
  const config = {
    url: `/merchant/${merchantId}/orders/${orderId}/`,
    method: "GET",
  };
  const response = client(config);
  return response;
}

export const addNewOrder = async (merchantId, params = {}) => {
  const config = {
    url: `/merchant/${merchantId}/orders/`,
    method: "POST",
    data: {
      status: "DRAFT",
      merchant: merchantId
    }
  };
  const response = client(config);
  return response;
};

export const addItemToOrder = (param = {}) => {
  const config = {
    url: `/orders/items/`,
    method: "POST",
    data: param
  };
  const response = client(config);
  return response;
};

export const updateItemQuantityToOrder = (orderItemId, quantity) => {
  const config = {
    url: `/orders/items/${orderItemId}/`,
    method: "PATCH",
    data: {
      'quantity': quantity,
    }
  };
  const response = client(config);
  return response;
}

export const removeItemFromOrder = (orderItemId) => {
  const config = {
    url: `/orders/items/${orderItemId}/`,
    method: "DELETE",
  };
  const response = client(config);
  return response;
}

export const addItemToCart = async (merchantId, orderId, params = {}) => {
  if (!orderId) {
    let orderResponse = await addNewOrder(merchantId);
    orderId = orderResponse.data.id;
    utils.setOrderId(orderId);
  }
  params.order = orderId;
  return addItemToOrder(params);
}

export const addCustomizations = async (order_item_id, customizations = []) => {
  const config = {
    url: `/orders/customizations/`,
    method: "POST",
    data: {
      "order_item": order_item_id,
      "customizations": customizations
    }
  };
  const response = client(config);
  return response;
}

export const updateOrder = async (merchantId, orderId, params) => {
  let qId = utils.getQR()?.id;
  const config = {
    url: `/merchant/${merchantId}/orders/${orderId}/`,
    method: "PATCH",
    data: {
      ...params,
      "merchant": merchantId,
      "merchant_qr": qId,
    }
  };
  const response = client(config);
  return response;
}

export const getOngoingOrderItems = () => {
  const config = {
    url: '/orders/items',
    method: 'GET',
  }
  const response = client(config)
  return response;
}

export const mapOrderToUser = (customerId, orderId) => {
  const config = {
    url: `/orders/user/`,
    method: "POST",
    data: {
      "user": customerId,
      "order": orderId
    }
  };
  const response = client(config);
  return response;
};


