import * as merchantService from "../Services/merchantService";
import * as utils from "../Services/utils";

export const capitalize = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export const validateAndSetQRDetails = async (merchantId, qrId) => {
    if (qrId) {
        const response = await merchantService.getMerchantQR(merchantId, qrId)
        if (response.status === 200) {
            if (response.data.is_active && !response.data.is_deleted) {
                utils.setQR(response.data);
                return response.data;
            }
        }
    }
    utils.clearQR();
}

export const getMenuPageURL = () => {
    const qrId = utils.getQR()?.id;
    const mId = utils.getMerchantId();
    if (qrId) {
        return `/merchant/${mId}?qId=${qrId}`;
    }
    return `/merchant/${mId}`;
}