import client from "./axiosConfig";
import * as constants from "../Constants";

export const customerLogin = (loginDetails) => {
  const config = {
    url: `/auth/customer-login/`,
    method: "POST",
    data: loginDetails
  };
  const response = client(config);
  return response;
};

export const sendOTP = (mobile_no) => {
  const config = {
    url: `/auth/otp/generate`,
    method: "POST",
    data: {
      mobile_no: mobile_no,
      role: constants.ROLE_CUSTOMER,
    }
  };
  const response = client(config);
  return response;
};

export const verifyOTP = (mobile_no, otp) => {
  const config = {
    url: `/auth/otp/verify`,
    method: "POST",
    data: {
      mobile_no: mobile_no,
      role: constants.ROLE_CUSTOMER,
      otp: otp,
    }
  };
  const response = client(config);
  return response;
};


