import React from 'react'
import Spinner from './Spinner'

function ButtonCounter({ increamentHandler, decreamentHandler, count, isLoading }) {

    return (
        <div className="h-10 w-20">
            <div className="flex flex-row bg-primary h-8 w-full rounded-lg mt-1 justify-center align-middle">

                {/* Showing item count if added in cart else show add label */}
                { isLoading
                    ? <Spinner radius={5}/>
                    :
                    <>
                        {count ?
                            (<>
                                <button
                                    className="text-gray-600 h-full w-20 rounded-l cursor-pointer outline-none"
                                    onClick={decreamentHandler}
                                >
                                    <span className="m-auto text-2xl font-thin">−</span>
                                </button>
                                <div className="flex justify-center text-gray-60 h-full w-20">
                                    <span className="m-auto font-semibold">{count}</span>
                                </div>
                                <button
                                    className="text-gray-600 h-full w-20 rounded-r cursor-pointer"
                                    onClick={increamentHandler}
                                >
                                    <span className="m-auto text-2xl font-thin">+</span>
                                </button>
                            </>)
                            : <div className="flex justify-center text-gray-60 h-full w-32 cursor-pointer" onClick={increamentHandler}>
                                <span className="m-auto">Add</span>
                            </div>
                        }
                    </>
                }
            </div>
        </div>
    )
}


export default ButtonCounter