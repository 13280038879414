import React, { useEffect, useRef, useContext } from "react";
import { useState } from "react";
import { FaArrowLeft } from "react-icons/fa";
import { MdQrCodeScanner } from "react-icons/md";
import { BsBag } from "react-icons/bs";
import { PiCookingPot, PiSignOutFill } from "react-icons/pi";
import { useNavigate } from "react-router-dom";
import { Visibility } from "./";
import { validateAndSetQRDetails, getMenuPageURL } from "../Utils";
import { UserBadge } from "../Components";
import { UserContext } from "../Contexts/userContext";
import * as utils from "../Services/utils";
import * as merchantService from "../Services/merchantService";
import * as orderService from "../Services/orderService";
import * as routes from "../routes";

const Header = () => {
  let navigate = useNavigate();
  let page = window.location.pathname;
  const [isLoading, setIsLoading] = useState(true);
  const [merchantName, setMerchantName] = useState("TechB Kiosk");
  const [showProfile, setShowProfile] = useState(false);
  const [QRName, setQRName] = useState("");
  const { user, logout } = useContext(UserContext);

  const wrapperRef = useRef(null);

  const handleNavigation = () => {
    navigate(-1, { replace: true });
  }

  const logoutHandler = () => {
    logout();
    navigate(getMenuPageURL(), { replace: true });
  }

  const openQRScanner = () => {
    navigate(routes.HOME, { replace: true });
  }

  const fetchMerchantName = () => {
    setIsLoading(true);
    let merchantId = utils.getMerchantId();
    if (merchantId) {
      merchantService.getMerchant(merchantId).then((response) => {
        if (response.data) {
          setMerchantName(response.data.name);
        }
        setIsLoading(false);
      });
    } else {
      setIsLoading(false);
    }
  }

  const fetchQRName = () => {
    let qrDetails = utils.getQR();
    if (qrDetails) {
      setQRName(qrDetails.label);
    } else {
      setQRName(null);
    }
  }

  const verifyAndClearOrderId = () => {
    let orderId = utils.getOrderId();
    if (orderId) {
      let merchantId = utils.getMerchantId();
      orderService.getOrder(merchantId, orderId).then((response) => {
        if (response.status !== 200) {
          utils.clearOrderId();
        }
      });
    }

  }

  const manageQRDetails = async () => {
    const url = new URL(window.location.href)
    let merchantId = url.pathname.split("/")[2];
    let pageUrl = url.pathname.split("/")[1];
    const merchantPageUrl = routes.MENU.split('/')[1];

    if (pageUrl === merchantPageUrl) {
      let qrId = url.searchParams.get('qId');
      if (qrId && merchantId) {
        await validateAndSetQRDetails(merchantId, qrId);
        fetchQRName()
      } else {
        utils.clearQR()
        fetchQRName()
      }
    }
  }

  const MenuItem = ({ icon, label, onClickAction }) => {
    // Navigation Menu Item

    return <div
      onClick={onClickAction}
      className="flex gap-2 px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer"
    >
      {icon}
      <span>{label}</span>
    </div>
  }

  useEffect(() => {
    if (page === "/") {
      utils.clearMerchantId();
      utils.clearQR();
    }

    manageQRDetails();
    fetchMerchantName();
    fetchQRName()
    verifyAndClearOrderId();
  }, [page])

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setShowProfile(false)
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  return (
    <nav className="flex items-center bg-primary fixed top-0 right-0 left-0 px-4 h-14 z-50 shadow-gray-300 shadow-sm">
      <div className="container flex flex-wrap justify-between items-center mx-auto">
        <div className="flex items-center gap-1">
          <Visibility hideOnPages={["", "merchant"]}>
            <FaArrowLeft onClick={handleNavigation} className="mr-2" />
          </Visibility>
          {
            isLoading
              ? <div className="h-4 w-24  bg-slate-200 mx-2 rounded animate-pulse"></div>
              :
              <div className="flex flex-col self-center" onClick={() => navigate(getMenuPageURL(), { replace: true })}>
          <span className="text-lg font-semibold whitespace-nowrap mr-2">{merchantName}</span>
          <span className="text-xs text-gray-500">{QRName}</span>
        </div>
          }

      </div>
      <div className="flex items-center justify-center gap-x-3">
        <Visibility hideOnPages={[""]}>
          <div onClick={openQRScanner}>
            <MdQrCodeScanner size={28} />
          </div>
        </Visibility>

        <Visibility hideOnPages={[""]}>
          {user &&
            <div onClick={() => setShowProfile(true)}>
              <UserBadge FirstName={user.first_name} LastName={user.last_name} size={1.5} />
            </div>
          }
        </Visibility>

        {showProfile && user &&
          <div
            className="fixed right-4 top-[50px] z-10 show bg-white rounded-lg shadow w-44"
            ref={wrapperRef}
          >
            <div className="px-4 py-3 text-sm text-gray-900 border-b-2 border-gray-100">
              <div>{user.first_name} {user.last_name}</div>
              <div className="font-medium truncate">+91 {user.mobile_no}</div>
            </div>

            <div className="flex flex-col divide-y divide-gray-100">
              <MenuItem
                icon={<PiCookingPot size={20} />}
                label="Ongoing Orders"
                onClickAction={() => navigate(routes.ORDER)}
              />
              <MenuItem
                icon={<BsBag size={18} />}
                label="Cart"
                onClickAction={() => navigate(routes.CART)}
              />
              <MenuItem
                icon={<PiSignOutFill size={20} />}
                label=" Sign out"
                onClickAction={logoutHandler}
              />
            </div>
          </div>
        }

      </div>
    </div>
    </nav >
  );
};

export default Header;
