import React, { useState, useEffect } from "react";

const ResendOTPCounter = ({ onTimerEnd }) => {
    const RESEND_OTP_INTERVAL = 60 // In Seconds
    const [timer, setTimer] = useState(RESEND_OTP_INTERVAL);

    const decrementTimer = () => {
        if (timer > 0) {
            setTimer(timer - 1);
        } else {
            onTimerEnd()
        }
    }

    useEffect(() => {
        const interval = setInterval(decrementTimer, 1000);
        return () => clearInterval(interval);
    }, [timer])


    return (
        <div>
            {
                timer > 0 &&
                <p className="text-gray-600 text-sm leading-4">
                    Resend OTP in <span className="text-gray-700">{timer}s</span>
                </p>
            }
        </div>
    )
}

export default ResendOTPCounter;