import React from "react";
import { BsArrowRightShort } from "react-icons/bs";
import { BottomBar } from "../Components";

const HomeOrderStatus = ({ count, icon, label, onClickHandler, actionLabel = "Next" }) => {
    const initialOpacity = count == 1 ? 0 : 1;
    const animation = { initial: initialOpacity, exit: 0, animate: 1 }

    return (
        <BottomBar onClick={onClickHandler} animation={animation}>
            <div className="flex justify-between align-middle items-center">
                <div className="flex items-center justify-center gap-x-2">
                    <button className="py-1.5 px-1 relative border-2 border-transparent text-gray-800 rounded-full transition duration-150 ease-in-out h-12" aria-label="Cart">
                        {icon}
                        <span className="absolute inset-0 top-3">
                            <div className="inline-flex items-center rounded-full text-xs text-black">
                                {count}
                            </div>
                        </span>
                    </button>
                    <h5>{label}</h5>
                </div>
                <div className="flex-col items-end">
                    <div className="flex items-center justify-center gap-x-1">
                        <p className="m-auto text-md">{actionLabel}</p>
                        <BsArrowRightShort size={20} />
                    </div>
                </div>
            </div>
        </BottomBar>
    )
}
export default HomeOrderStatus;