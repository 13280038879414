import React from "react";

const Repeater = ({ children, count = 1 }) => {
    return (
        <>
            {
                Array(count).fill(0).map(() => {
                    return children;
                })
            }
        </>
    )
}
export default Repeater;