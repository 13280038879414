import {React} from "react";

const ItemCard = () => {
    return (
        <div className="py-4 mx-auto border-b-2 border-b-slate-100">
        <div className="flex space-x-4">
            <div className="flex-1 space-y-2 py-1">
                <div className="h-5 w-5 bg-slate-200 rounded-full"></div>
                <div className="space-y-3">
                    <div className="h-2 w-32 bg-slate-200 rounded col-span-2"></div>
                    <div className="h-2 w-48  bg-slate-200 rounded"></div>
                </div>
            </div>
            <div className="bg-slate-200 h-16 w-16"></div>
        </div>
        </div>
    );
};

export default ItemCard;