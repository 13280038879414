import React from "react";
import { useEffect, useState } from "react";
import { ItemCard, Button, BottomBar } from "../Components";
import { useNavigate } from "react-router-dom";
import { IoFastFoodOutline } from "react-icons/io5";
import { BsArrowLeftShort } from "react-icons/bs";
import { CartSkeleton } from "./Skeletons"
import { getMenuPageURL } from "../Utils";
import * as orderService from "../Services/orderService";

const Order = () => {
  const [orderItems, setOrderItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  let navigate = useNavigate();

  const getOngoingOrderItems = async () => {
    setIsLoading(true);
    const response = await orderService.getOngoingOrderItems();
    if (response.status === 200) {
      setOrderItems(response.data.results);
    }
    setIsLoading(false);
    return response;
  }

  const getCustomizationDescription = (customizations) => {
    let description = [];
    description = customizations.map(customization => {
      return customization.name
    })
    return description.join(", ")
  }

  const MenuItems = () => {
    return orderItems.map((orderedItem) => {
      return <ItemCard
        name={orderedItem.item.name}
        description={getCustomizationDescription(orderedItem.customizations)}
        has_customization={orderedItem.item.has_customization}
        price={orderedItem.total_price / orderedItem.quantity}
        quantity={orderedItem.quantity}
        is_veg={orderedItem.item.is_veg}
        key={orderedItem.id}
        status={orderedItem.status}
        view_only={true} />
    })

  }

  const OrderDetails = () => {
    return <>
      <div className="text-md font-bold">Your Ongoing Orders</div>

      <div className="mb-24">
        <div className="my-4">{MenuItems()}</div>
      </div>
    </>;
  }

  const NoItems = () => {
    return <>
      <div className="flex flex-col align-middle items-center container justify-center h-[60vh]">
        <div><IoFastFoodOutline size={100} /></div>
        <div className="py-4">
          <span className="text-xl">Order Your Food Now!!</span>
        </div>
        <Button
          width="w-52"
          bgColor="bg-secondary-200"
          textColor="black"
          onClick={() => navigate(getMenuPageURL())}
          type="submit"
        >
          Browse Menu
        </Button>
      </div>
    </>
  }

  useEffect(() => { getOngoingOrderItems() }, []);

  return (
    <div className="w-ful md:w-2/5">
      {
        isLoading
          ? <CartSkeleton />
          : orderItems.length === 0 ? NoItems() : OrderDetails()
      }
      <div>
        {
          !isLoading &&
          <BottomBar onClick={() => navigate(getMenuPageURL())}>
            <div className="flex gap-2 items-center justify-center h-12 text-sm">
              <BsArrowLeftShort size={30} />
              <span>Back to Menu</span>
            </div>
          </BottomBar>
        }
      </div>
    </div>
  );

};

export default Order;
