import React from "react";
import { motion } from "framer-motion"

const BottomBar = ({ children, animation = { initial: 0, animate: 1, exit: 0 }, onClick = {} }) => {
    return (
        <motion.div
            className="flex fixed mt-5 p-0 w-full md:w-2/5 bottom-0 left-0"
            initial={{ opacity: animation.initial }}
            animate={{ opacity: animation.animate }}
            exit={{ opacity: animation.exit }}
        >
            <div
                className="bg-secondary-200 h-full w-full rounded cursor-pointer py-1 px-4"
                onClick={onClick}
            >
                {children}
            </div>
        </motion.div>
    )
}
export default BottomBar;