import React, { useState, useRef } from "react";

const OTPInput = ({ OTPHandler }) => {
    const OTP_LENGTH = 5;
    const [otp, setOtp] = useState(Array(OTP_LENGTH).fill(''));
    const digitRefs = useRef([]);

    const onChangeOTP = (e) => {
        const value = e.target.value;
        const index = Number(e.target.id);

        if (value && !isNaN(value) && e.keyCode !== 8) {
            let _otp = [...otp]
            _otp[index] = value;
            setOtp(_otp);
            if (index < otp.length - 1) {
                digitRefs.current[index + 1].focus();
            }
            if (index === OTP_LENGTH - 1) {
                const final_otp = _otp.join('')
                OTPHandler(final_otp);
            }
            return;
        }
        digitRefs.current[index] = '';
    }

    const onDigitDelete = (e) => {
        const index = Number(e.target.id);
        if (e.keyCode === 8) {
            let _otp = [...otp]
            _otp[index] = '';
            if (index > 0) {
                digitRefs.current[index - 1].focus();
                digitRefs.current[index - 1] = '';
            }
            setOtp(_otp);
            return;
        }
    }

    return (
        <div className="flex flex-col gap-2">
            <div>
                <p className="text-base text-gray-700">Enter OTP</p>
            </div>
            <div className="flex flex-col justify-center gap-2">
                <div className="flex gap-2">
                    {
                        otp.map((digit, idx) =>
                            <input
                                id={idx}
                                key={idx}
                                type="text"
                                maxLength={1}
                                minLength={1}
                                value={digit}
                                ref={el => digitRefs.current[idx] = el}
                                onChange={onChangeOTP}
                                onKeyDown={onDigitDelete}
                                className="p-2 text-md w-[53px] h-[50px] text-gray-700 text-center rounded-md border-gray-300 mr-0  invalid:border-rose-500 invalid:focus:ring-0"
                            />
                        )
                    }
                </div>
            </div>
        </div>
    )
}

export default OTPInput;