import React from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";

/**
 * Component for popups
 * @param {string} props.title
 * @param {string} props.description
 * @param {jsx} props.children
 * @param {function} props.closeHandler  
 */
const Popup = (props) => {
  return (
    <div className="flex items-center justify-center overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal md:h-full bg-gray-500/40">
      <div className="w-full max-w-md h-full md:h-auto">

        {/* <!-- Modal content --> */}
        <div className="w-full fixed bottom-0 md:static bg-white rounded-t-lg">

          {/* <!-- Modal header --> */}
          <div className="flex justify-between py-4 px-6 rounded-t border-b">
            <h3 className="text-base font-semibold text-gray-900 lg:text-xl">
              {props.title}
              <p className="text-sm font-normal text-gray-500 pt-1">{props.description}</p>
            </h3>
            <div className="relative p-1.5 ml-auto inline-flex items-center">
              <AiOutlineCloseCircle size={30} onClick={props.closeHandler} />
            </div>
          </div>

          {/* <!-- Modal body --> */}
          <div>
            {props.children}
          </div>
        </div>
      </div>
    </div>
  )
}
export default Popup;