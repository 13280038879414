import client from "./axiosConfig";

const getMerchant = (merchantId) => {
  const config = {
    url: `/merchant/${merchantId}/`,
    method: "GET",
  };
  const response = client(config);
  return response;
}

const getMerchantQR = (merchantId, qId) => {
  const config = {
    url: `/merchant/${merchantId}/qr/${qId}/`,
    method: "GET",
  };
  const response = client(config);
  return response;
}


export { getMerchant, getMerchantQR };
