import React, { useState, useRef, useContext, useEffect } from "react";
import { Formik, Field, Form } from 'formik';
import { UserContext } from "../Contexts/userContext";
import * as Components from "../Components";
import * as Yup from "yup";
import * as customerService from "../Services/customerLoginService";

const LoginForm = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [OTPSent, setOTPSent] = useState(false);
    const [verifyingOTP, setVerifyingOTP] = useState(false);
    const [resendDisabled, setResendDisabled] = useState(true);
    const [userDetails, setUserDetails] = useState(null);
    const [error, setError] = useState(null);

    const { user, login } = useContext(UserContext);

    const customerLoginSchema = Yup.object().shape({
        first_name: Yup.string()
            .min(2, 'First name is too short')
            .required('First name is required'),
        last_name: Yup.string()
            .min(2, 'Last name is too short')
            .required('Last name is required'),
        mobile_no: Yup.string()
            .length(10, 'Enter valid mobile number')
            .required('Mobile number is required'),
    });

    const sendOTPServiceCall = (mobile_no) => {
        customerService.sendOTP(mobile_no).then(response => {
            if (response.status == 200) {
                setOTPSent(true);
            } else {
                setIsLoading(false);
            }
        })
    }

    const authenticateCustomer = (values) => {
        setIsLoading(true);
        customerService.customerLogin(values).then(response => {
            if (response.status === 200) {
                setUserDetails(response.data);
                sendOTPServiceCall(values['mobile_no']);
            }
        });
    }

    const resendOTPHandler = () => {
        setResendDisabled(true);
        sendOTPServiceCall(userDetails['mobile_no']);
    }

    const verifyOTPHandler = (otp) => {
        setVerifyingOTP(true)
        customerService.verifyOTP(userDetails['mobile_no'], otp).then(response => {
            if (!response.error && response.status == 200) {
                login(response.data);
            } else {
                setError(response?.error?.message)
                setVerifyingOTP(false);
                setResendDisabled(false);
            }
        })
    }

    // Verifying OTP
    if (verifyingOTP) {
        return (
            <div className="flex gap-2 justify-center items-center my-6">
                <Components.Spinner />
                <div>Verifying OTP! Please wait...</div>
            </div>
        )
    }

    // Show OTP verification screen
    if (OTPSent) {
        return (
            <div>
                <div className="flex flex-col gap-4 p-4">
                    <div>
                        <Components.OTPInput OTPHandler={verifyOTPHandler} />
                    </div>
                    <div>
                        {
                            resendDisabled &&
                            <Components.ResendOTPCounter onTimerEnd={() => setResendDisabled(false)} />
                        }
                        {
                            error &&
                            <p className="text-sm text-red-500">{error}</p>
                        }
                    </div>
                </div>
                <div className="flex justify-center p-4 border-t text-base font-semibold text-gray-900">
                    <Components.Button
                        width="w-52"
                        bgColor="bg-secondary-200"
                        textColor="black disabled:text-gray-500"
                        onClick={resendOTPHandler}
                        disabled={resendDisabled}
                    >
                        Resend OTP
                    </Components.Button>
                </div>
            </div>
        )
    }

    // If OTP is not generated show login form
    return (
        <Formik
            initialValues={{
                first_name: '',
                last_name: '',
                mobile_no: '',
            }}
            validationSchema={customerLoginSchema}
            onSubmit={authenticateCustomer}
        >
            {({ errors, touched }) => (
                <Form>
                    <div className="flex flex-col gap-4 p-4">
                        <div className="grid grid-cols-2 gap-4">
                            <div>
                                <Field
                                    className="appearance-none border-gray-200 rounded w-full p-3 text-gray-700 focus:outline-none focus:shadow-outline"
                                    name="first_name"
                                    type="text"
                                    placeholder="First Name"
                                    disabled={isLoading}
                                />
                                {
                                    errors.first_name && touched.first_name ? (
                                        <p className="text-red-500 text-xs mt-1">{errors.first_name}</p>
                                    ) : null
                                }
                            </div>

                            <div>
                                <Field
                                    className="appearance-none border-gray-200 rounded w-full p-3 text-gray-700 focus:outline-none focus:shadow-outline"
                                    name="last_name"
                                    type="text"
                                    placeholder="Last Name"
                                    disabled={isLoading}
                                />
                                {
                                    errors.last_name && touched.last_name ? (
                                        <p className="text-red-500 text-xs mt-1">{errors.last_name}</p>
                                    ) : null
                                }
                            </div>
                        </div>

                        <div>
                            <Field
                                className="appearance-none border-gray-200 rounded w-full p-3 text-gray-700 focus:outline-none focus:shadow-outline"
                                name="mobile_no"
                                type="number"
                                placeholder="Mobile Number"
                                disabled={isLoading}
                            />
                            {errors.mobile_no && touched.mobile_no ? (
                                <p className="text-red-500 text-xs mt-1">{errors.mobile_no}</p>
                            ) : null}
                        </div>

                        <div>
                            <p className="text-gray-400 text-xs leading-4">
                                By signing in you agree terms & condition and privacy policy
                            </p>
                        </div>
                    </div>
                    <div className="flex justify-center py-4 border-t">
                        <div className="font-semibold text-gray-900">
                            <Components.Button
                                width="w-52"
                                bgColor="bg-secondary-200"
                                textColor="black"
                                disabled={isLoading}
                            >
                                {isLoading ? (
                                    <p className="flex justify-center gap-1">
                                        <Components.Spinner radius={5} /> Sending OTP
                                    </p>
                                ) : "Get OTP"}
                            </Components.Button>
                        </div>
                    </div>
                </Form>
            )}
        </Formik >
    )
}
export default LoginForm;