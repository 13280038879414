import React from "react";
import { useEffect, useState, useContext } from "react";
import { ItemCard, Button, Popup, LoginForm, BottomBar } from "../Components";
import { useNavigate } from "react-router-dom";
import { IoFastFoodOutline } from "react-icons/io5";
import { CartSkeleton } from "./Skeletons"
import { getMenuPageURL } from "../Utils";
import { UserContext } from "../Contexts/userContext";
import * as orderService from "../Services/orderService";
import * as utils from "../Services/utils";
import * as constants from "../Constants";

const Cart = () => {
  const { user } = useContext(UserContext);
  const [order, setOrder] = useState({ total_price: 0 });
  const [orderNote, setOrderNote] = useState('');
  const [orderItems, setOrderItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showLoginModal, setLoginModal] = useState(!user);
  const [showToaster, setToaster] = useState(false);
  const [toasterMessage, setToasterMessage] = useState({});

  let navigate = useNavigate();

  const getOrder = async () => {
    setIsLoading(true);
    let orderId = utils.getOrderId();
    let merchantId = utils.getMerchantId();
    const response = await orderService.getOrder(merchantId, orderId);
    if (response.status === 200) {
      setOrder(response.data);
      setOrderItems(response.data.items);
    }
    setIsLoading(false);
    return response;
  }

  const incrementQuantity = async (orderedItem) => {
    setIsLoading(true);
    let quantity = orderedItem.quantity + 1;
    await orderService.updateItemQuantityToOrder(orderedItem.id, quantity);
    await getOrder();
    setIsLoading(false);
  }

  const decrementQuantity = async (orderedItem) => {
    setIsLoading(true);
    let quantity = orderedItem.quantity - 1;
    if (quantity === 0) {
      await orderService.removeItemFromOrder(orderedItem.id);
    } else {
      await orderService.updateItemQuantityToOrder(orderedItem.id, quantity);
    }
    await getOrder();
    setIsLoading(false);
  }

  const getCustomizationDescription = (customizations) => {
    let description = [];
    description = customizations.map(customization => {
      return customization.name
    })
    return description.join(", ")
  }

  const placeOrderServiceCall = () => {
    let orderId = utils.getOrderId();
    let merchantId = utils.getMerchantId();
    const params = {
      "status": "PLACED",
      "note": orderNote,
    }
    orderService.updateOrder(merchantId, orderId, params).then(response => {
      if (response.status === 200) {
        utils.clearOrderId();
        createToasterMessage("Order Placed Successfully", "Your order will be served soon");
      } else {
        createToasterMessage("Fail to place your order", "Please try again");
      }
      setToaster(true);
    });
  }

  const placeOrder = () => {
    const customer = utils.getCustomer();
    const orderId = utils.getOrderId();

    orderService.mapOrderToUser(customer.id, orderId).then(mappingResponse => {
      if (mappingResponse.status === 201) {
        placeOrderServiceCall();
      } else if (mappingResponse.status === 400) {
        const errorMsgs = utils.getErrorsFromResponse(mappingResponse.error);
        const isUserOrderAlreadyExist = errorMsgs.includes(constants.USER_ORDER_ERROR_ALREADY_EXIST);
        if (isUserOrderAlreadyExist) {
          placeOrderServiceCall();
        } else {
          createToasterMessage("Fail to place your order", "Please try again");
          setToaster(true);
        }
      }
    });
  }

  const createToasterMessage = (toasterTitle, toasterDescription) => {
    setToasterMessage({
      "title": toasterTitle,
      "description": toasterDescription,
    });
  }


  const closeToaster = () => {
    setToaster(false);
    navigate(getMenuPageURL());
  }

  const MenuItems = () => {
    return orderItems.map((orderedItem) => {
      return <ItemCard
        name={orderedItem.item.name}
        description={getCustomizationDescription(orderedItem.customizations)}
        has_customization={orderedItem.item.has_customization}
        price={orderedItem.total_price / orderedItem.quantity}
        incrementQuantity={() => incrementQuantity(orderedItem)}
        decrementQuantity={() => decrementQuantity(orderedItem)}
        quantity={orderedItem.quantity}
        is_veg={orderedItem.item.is_veg}
        key={orderedItem.id}
        view_only={false} />;
    })

  }

  const OrderDetails = () => {
    return <>
      <div className="text-md font-bold">Your Cart</div>

      <div className="mb-24">
        <div className="my-4">{MenuItems()}</div>
        <div>
          <div className="my-1">
            <label htmlFor="note" className="block mb-2 text-sm font-medium text-gray-900">
              Special Cooking Instructions
            </label>
            <textarea
              id="note"
              rows="3"
              value={orderNote}
              onChange={(e) => setOrderNote(e.target.value)}
              className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300"
              placeholder="Add cooking instructions here...">
            </textarea>
            <p className="text-xs text-gray-600 py-1">
              We will try our best to follow your instructions.
            </p>
          </div>
        </div>
      </div>

      <BottomBar onClick={() => user ? placeOrder() : setLoginModal(true)}>
        <div className="flex justify-between align-middle items-center">
          <div className="flex-col items-start">
            <h4 className="text-semibold"> ₹ {order.total_price}</h4>
            <h6> Total </h6>
          </div>
          <div className="flex-col items-end">
            <span className="m-auto text-md">Place Order</span>
          </div>
        </div>
      </BottomBar>
    </>;
  }

  const NoItems = () => {
    return <>
      <div className="flex flex-col align-middle items-center container justify-center h-[60vh]">
        <div><IoFastFoodOutline size={100} /></div>
        <div className="py-4">
          <span className="text-xl">Order Your Food Now!!</span>
        </div>
        <Button
          width="w-52"
          bgColor="bg-secondary-200"
          textColor="black"
          onClick={() => navigate(getMenuPageURL())}
          type="submit"
        >
          Browse Menu
        </Button>
      </div>
    </>
  }

  const Toaster = () => {
    return <>
      <Popup
        title={toasterMessage.title}
        description={toasterMessage.description}
        closeHandler={() => closeToaster()}
      >
      </Popup>
    </>
  }

  useEffect(() => { getOrder() }, []);
  useEffect(() => { setLoginModal(!user) }, [user])

  return (
    <div className="w-ful md:w-2/5">
      {
        isLoading
          ? <CartSkeleton />
          : orderItems.length === 0 ? NoItems() : OrderDetails()
      }
      {
        showLoginModal &&
        <Popup
          title="Please login to place order!"
          description="Please enter your contact details"
          closeHandler={() => setLoginModal(false)}
        >
          <LoginForm />
        </Popup>
      }
      {showToaster ? Toaster() : null}
    </div>
  );

};

export default Cart;
