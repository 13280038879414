import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button, QRScanner } from "../Components";
import qr_scanning from "../Assets/Images/qr-scanning.gif";
import * as utils from "../Utils";

const Home = () => {
  const navigate = useNavigate();
  const [scanning, setScanning] = useState(false);
  const [QRresult, setQRresult] = useState("");
  const [scanNote, setScanNote] = useState("");

  const handleScan = (result) => {
    setQRresult(result);
  }

  const getPathNameToRedirect = (url) => {
    let hrefParts = url.href.split('/');
    hrefParts = hrefParts.filter((part, idx) => {
      const skipIdx = [0, 1, 2];
      return !skipIdx.includes(idx);
    })
    return hrefParts.join("/");
  }

  const navigateToMerchant = async () => {
    const current_host = window.location.hostname;
    try {
      const url = new URL(QRresult)
      navigator.vibrate(200);
      if (url.hostname === current_host) {
        let merchantId = url.pathname.split("/")[2];
        let qrId = url.searchParams.get('qId');
        await utils.validateAndSetQRDetails(merchantId, qrId);
        navigate(getPathNameToRedirect(url));
        setScanning(false);
      } else {
        setScanNote("Please scan correct QR code");
      }
    } catch (error) {
      setScanNote("Scan a QR code");
    }
  }



  useEffect(() => {
    navigateToMerchant()
    // eslint-disable-next-line
  }, [QRresult])

  return (
    <>
      <div className="flex flex-col items-center justify-center">
        {scanning && (
          <QRScanner
            resultHandler={(resp) => handleScan(resp)}
            note={scanNote}
          />
        )}


        {!scanning && (
          <>
            <div className="bg-slate-100 min-h-[200px] min-w-[200px] rounded-full mt-24">
              <img src={qr_scanning} alt="Scan QR code" />
            </div>

            <div className="mt-4 flex flex-col items-center justify-center">
              <Button
                width="w-52"
                bgColor="bg-secondary-200"
                textColor="black"
                onClick={() => setScanning(true)}
              >Scan now</Button>
              <h1 className="text-sm text-gray-400 pt-1 pr-2">Please allow camera access to scan QR!</h1>
              <div className="bg-green-100 text-green-800 px-3 py-2 mt-10 rounded w-80  text-center">
                <span className="text-xs rounded">
                  In case of issues with above scanner, use apps like Google Lens, Gpay, or PayTm to scan QR code.
                </span>
              </div>
            </div>

          </>
        )}
      </div>
    </>
  );
};

export default Home;
