import React from "react";

const Visibility = ({ children, showOnPages = [], hideOnPages = [] }) => {

    const isVisible = () => {
        if (showOnPages.length > 0 && hideOnPages.length > 0)
            throw "Error in props";

        if (showOnPages.length === 0 && hideOnPages.length === 0)
            return false // Default visibility

        let pagePath = window.location.pathname.split("/")[1];

        if (hideOnPages.length > 0) {
            if (hideOnPages.indexOf(pagePath) !== -1)
                return false;
            else return true;

        }

        if (showOnPages.length > 0) {
            if (showOnPages.indexOf(pagePath) !== -1)
                return true;
            else return false;
        }
    }

    return (
        <>
            {
                isVisible() && children
            }
        </>
    )
}
export default Visibility;