import client from "./axiosConfig";

const getMenuItem = (merchantId, params={}) => {
  const config = {
    url: `/menu/${merchantId}/item/`,
    method: "GET",
    params: {
      is_active: true,
      category__is_active: true,
      limit: 300,
      ...params
    }
  };
  const response = client(config);
  return response;
};

const getMenuItemByCategory = (merchantId, categoryId) => {
  const config = {
    url: `/menu/${merchantId}/item/?is_active=true&&category=${categoryId}`,
    method: "GET",
    params: {
      limit: 300,
    }
  };
  const response = client(config);
  return response;
};


const getCustomizationType = (merchantId, itemId) => {
  const config = {
    url: `/menu/${merchantId}/item/${itemId}/customization-type/?is_active=true`,
    method: "GET",
    params: {
      limit: 200,
    }
  };
  const response = client(config);
  return response;
};


export { getMenuItem, getMenuItemByCategory, getCustomizationType };
