import * as utils from "../Utils"

export const getMerchantId = () => {
  return localStorage.getItem('mId');
}

export const getCustomer = () => {
  try {
    return JSON.parse(localStorage.getItem('cId'));
  } catch (error) {
    clearCustomer();
  }
}

export const setCustomer = (details) => {
  return localStorage.setItem('cId', JSON.stringify(details));
}

export const clearCustomer = () => {
  return localStorage.removeItem('cId');
}

export const setMerchantId = (merchantId) => {
  localStorage.setItem('mId', merchantId);
}

export const getOrderId = () => {
  let merchantId = getMerchantId();
  return localStorage.getItem('oId-' + merchantId);
}

export const setOrderId = (orderId) => {
  let merchantId = getMerchantId();
  return localStorage.setItem('oId-' + merchantId, orderId);
}

export const clearOrderId = () => {
  let merchantId = getMerchantId();
  return localStorage.removeItem('oId-' + merchantId);
}

export const clearMerchantId = () => {
  return localStorage.removeItem('mId');
}

export const getErrorsFromResponse = (error) => {
  /**
   * Extract and return list of error messages from 
   * response errors
   * @param {Array[object]} errors List of errors from response i.e response.error
   * @returns {Array[string]} errors
   */
  
  let _errors = []
  if (Object.keys(error).includes('errors')) {
    Object.keys(error.errors).forEach(key => {
      const errorKey = utils.capitalize(key);
      error.errors[key].forEach(msg => {
        const errorMsg = utils.capitalize(msg);
        const fullMsg = `${errorKey}: ${errorMsg}`;
        _errors.push(fullMsg);
      });
    });
  }
  return _errors;
}

export const setQR = (qrData) => {
  return localStorage.setItem('qId', JSON.stringify(qrData));
}

export const getQR = () => {
  return JSON.parse(localStorage.getItem('qId'));
}

export const clearQR = () => {
  return localStorage.removeItem('qId');
}
