import React from "react";
import { Repeater } from "../../Components";
import {ItemCard} from "../../Components/Skeletons";

const CartSkeleton = () => {

    return (
        <div className="animate-pulse w-full">
            <div className="h-2 w-2/5 bg-slate-200 rounded mb-5"></div>

            <Repeater count={4}>
              <ItemCard></ItemCard>
            </Repeater>

            <div className="flex fixed p-2 bg-white mt-5 w-full md:w-2/5 bottom-0 left-0">
                <div className="bg-slate-100 h-12 w-full rounded">
                </div>
            </div>
        </div>
    );

};

export default CartSkeleton;