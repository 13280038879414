import React, { useEffect, useState, useRef, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { getMenuItem } from "../Services/menuService";
import { PiCookingPot } from "react-icons/pi";
import { BsBag } from "react-icons/bs";
import { MenuCard, SearchBox, Toggle, HomeOrderStatus } from "../Components";
import { IoFastFoodOutline } from "react-icons/io5";
import { UserContext } from "../Contexts/userContext";
import * as orderService from "../Services/orderService";
import * as utils from "../Services/utils";
import * as skeletons from "./Skeletons";
import * as routes from "../routes";

const Menu = () => {
  const [menuByCategories, setMenuByCategories] = useState({});
  const [categories, setCategories] = useState([]);
  const [isVegOnly, setVegOnlyStatus] = useState(false);
  const [isNonVegOnly, setNonVegOnlyStatus] = useState(false);
  const [searchKeywords, setSearchKeywords] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isCategoryListDisplayed, setCategoryListDisplayState] = useState(false);
  const [totalCartItems, setTotalCartItems] = useState(0);
  const [ongoingItems, setTotalOngoingItems] = useState(0);

  const { user } = useContext(UserContext);
  const navigate = useNavigate();

  const categoryRefs = useRef([]);
  const { merchantId } = useParams();

  if (merchantId) {
    utils.setMerchantId(merchantId);
  }

  const categorizeMenuItems = (menuItems, orderItems) => {
    let menuByCategoryId = {};
    let categories = [];

    menuItems.forEach(menu => {
      let existing_categories = Object.keys(menuByCategoryId);
      let category = menu.category;
      let categoryId = category.id;

      if (!existing_categories.includes(categoryId)) {
        menuByCategoryId[categoryId] = [];
        categories.push(category);
      }

      // Order details if item exist in order
      let orderMenuItems = orderItems.filter((orderItem) => {
        return orderItem.item.id === menu.id;
      });

      menu.order = orderMenuItems;
      menuByCategoryId[categoryId].push(menu);
    });
    setMenuByCategories(menuByCategoryId);
    setCategories(categories);
    setIsLoading(false);
  }

  const scrollToCategory = (idx) => {
    setCategoryListDisplayState(false);
    const element = categoryRefs.current[idx];
    const offsetY = -65;
    const scrollTo = element.getBoundingClientRect().top + window.pageYOffset + offsetY;
    window.scrollTo({ top: scrollTo, behavior: "smooth" })
  }

  const getMenuItems = (params) => {
    getMenuItem(merchantId, params).then((menuResponse) => {
      const menuItems = menuResponse.data.results;
      if (utils.getOrderId()) {
        let orderId = utils.getOrderId();
        orderService.getOrder(merchantId, orderId).then((orderResponse) => {
          const orderItems = orderResponse.data.items;
          categorizeMenuItems(menuItems, orderItems);
          setTotalCartItems(orderItems.length);
        });
      } else {
        categorizeMenuItems(menuItems, []);
        setTotalCartItems(0);
      }
    });
  }

  const getOngoingOrderItems = async () => {
    if(!user) return;
    const response = await orderService.getOngoingOrderItems();
    if (response.status === 200) {
      setTotalOngoingItems(response.data.results.length);
    }
    return response;
  }

  const NoItems = () => {
    return <>
      <div className="flex flex-col align-middle items-center container justify-center h-[60vh]">
        <div><IoFastFoodOutline size={100} /></div>
        <div className="py-4">
          <span className="text-xl">Menu items not available</span>
        </div>
      </div>
    </>
  }

  const HomeOrderStatusBar = () => {
    if (totalCartItems > 0) {
      return <HomeOrderStatus
        count={totalCartItems}
        icon={<BsBag size={30} />}
        label={"Items in cart"}
        onClickHandler={() => navigate(routes.CART)}
      />
    }
    else if (ongoingItems > 0 && user) {
      return <HomeOrderStatus
        count={ongoingItems}
        icon={<PiCookingPot size={34} />}
        label={"Items"}
        actionLabel="Ongoing Order"
        onClickHandler={() => navigate(routes.ORDER)}
      />
    }
  }

  useEffect(() => {
    let params = { search: searchKeywords }
    if (!(isVegOnly === isNonVegOnly)) {
      params['is_veg'] = isVegOnly;
    }
    getMenuItems(params);
    getOngoingOrderItems();
  }, [merchantId, isVegOnly, isNonVegOnly, searchKeywords]);

  return (
    <>
      <div className="mb-20">
        {/* Search box */}
        <div className="w-full md:w-2/5 mb-4">
          <SearchBox
            searchBoxName="search-menu"
            onInputChange={(el) => setSearchKeywords(el.target.value)}
          />
        </div>

        {/* Filter listing */}
        <div className="w-full md:w-3/5 flex gap-10">
          <Toggle
            toggleName="Veg Only"
            toggleStatus={isVegOnly}
            onToggleChange={() => setVegOnlyStatus(!isVegOnly)}
          />
          <Toggle
            toggleName="Non Veg Only"
            toggleStatus={isNonVegOnly}
            onToggleChange={() => setNonVegOnlyStatus(!isNonVegOnly)}
          />
        </div>

        {/* Menu Item Listing */}
        <div className="w-full md:w-2/5">
          {
            isLoading ?
              <skeletons.MenuItem />
              : Object.keys(menuByCategories).length > 0
                ? (categories.map((category) => (
                  <div key={category.id} id={category.id} ref={el => (categoryRefs.current = [...categoryRefs.current, el])}>
                    <div className="my-4 text-md font-bold" >
                      <span>{category.name}</span>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-5">
                      {
                        menuByCategories[category.id].map((item) => (
                          <MenuCard
                            item={item}
                            key={item.id}
                            onItemAdded={() => setTotalCartItems(totalCartItems + 1)}
                            onItemRemoved={() => setTotalCartItems(totalCartItems - 1)}
                          />
                        ))
                      }
                    </div>
                  </div>
                )))
                : NoItems()
          }
        </div>

        {/* Category button and list */}
        <>
          {
            isCategoryListDisplayed ?
              <>
                <div className="flex items-center justify-center overflow-y-auto overflow-x-hidden fixed bottom-0 right-0 left-0 w-full md:inset-0 h-modal md:h-full bg-gray-500/40" />
                <div className={`fixed ${totalCartItems > 0 || ongoingItems > 0 ? 'bottom-28' : 'bottom-16'} mb-3 left-[30%] w-48 max-h-64 rounded-md overflow-auto bg-white text-center text-sm`}>
                  {
                    Object.keys(menuByCategories).length > 0
                      ? (categories.map((category, idx) => (
                        <div
                          key={category.id}
                          className="px-2 py-3 cursor-pointer"
                          onClick={() => scrollToCategory(idx)}
                        >
                          {category.name}
                        </div>
                      )))
                      : null
                  }
                </div>
              </>
              : null
          }
        </>

        <button className={`fixed ${totalCartItems > 0 || ongoingItems > 0 ? 'bottom-12' : 'bottom-2'} left-[30%] w-48 h-10 mb-5 mt-2 text-white bg-black rounded-full cursor-pointer text-sm disabled:bg-slate-100`}
          onClick={() => setCategoryListDisplayState(!isCategoryListDisplayed)}
          disabled={isLoading || Object.keys(menuByCategories).length === 0 ? true : false}
        >
          {!isCategoryListDisplayed ? "Browse Menu" : "x Close"}
        </button>
      </div>

      <HomeOrderStatusBar />
    </>
  );
};

export default Menu;
