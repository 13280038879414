import React, { useState } from "react";
import QrReader from "react-qr-scanner";
import Toggle from "./Toggle";

const QRScanner = ({ resultHandler, note }) => {
    const [data, setData] = useState()
    const [environment, setEnvironment] = useState("rear")

    const handleScan = async (qrData) => {
        if (qrData && qrData !== "") {
            setData(qrData)
            resultHandler(qrData)
        }
    };

    const handleError = (err) => {
        console.error(err);
    };

    const switchEnvironment = () => {
        environment === "rear"
            ? setEnvironment("front")
            : setEnvironment("rear");
    };

    return (
        <div>
            <div className="fixed bottom-0 left-0 z-50 bg-white w-full round-t-sm">

                <div className="flex justify-center align-middle items-center mt-4">
                    <span className="text-sm text-gray-400">{note}</span>
                </div>
                <div className="flex justify-center align-middle items-center mb-4">
                    <span className="px-2 ">Front Camera</span>
                    <div className=" px-2 mt-5">
                        <Toggle
                            toggleStatus={environment === "rear" ? true : false}
                            onToggleChange={() => switchEnvironment()}
                        />
                    </div>
                    <span className="px-2">Back Camera</span>
                </div>
            </div>
            <div className="relative">
                <div className="shadow-[0_0_0_9999px_rgb(0,0,0,0.9)] absolute top-6 left-0 right-0 mx-auto h-[300px] w-[300px]" />

                <div className="z-10">
                    <QrReader
                        delay={100}
                        onError={handleError}
                        onScan={handleScan}
                        facingMode={environment}
                    />
                </div>
            </div>
        </div>

    );
}

export default QRScanner;
