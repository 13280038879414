import React from 'react'

const Button = ({ bgColor, textColor, children, onClick, width="w-full", disabled=false }) => {

    return (
        <button 
        disabled={disabled}
        className={`rounded h-11 w-full py-2 ${bgColor} ${textColor} ${width}`}
        onClick={onClick}
        >
           {children}
        </button>
    )
}

export default Button;