import { ButtonCounter } from ".";
import * as icons from "../Assets/icons";
/**
 * Component for showing menu item details.
 * @param {boolean} props.is_veg
 * @param {string}  props.name
 * @param {float}   props.price
 * @param {string}  props.description
 * @param {url}     props.image
 * @param {integer} props.quantity
 * @param {boolean} props.isLoading
 * @param {boolean} props.has_customization
 * @param {method}  props.increamentQuantity
 * @param {method}  props.decreamentHandler
 * @param {boolean} props.view_only
 * @param {string}  props.status
 */
const ItemCard = (props) => {
  const view_only = props.view_only;

  return (
    <div>
      <div className="border-b-2 border-gray-100 text-sm">
        <div className="bg-white max-w-lg rounded py-2">
          <div className="flex justify-between">
            <div className="flex flex-col justify-center items-start">
              {
                !view_only &&
                <span className={`w-2 h-2 rounded-full ${props.is_veg ? 'bg-green-500' : 'bg-red-500'}`}></span>
              }
              {
                !view_only
                  ? <span>{props.name}</span>
                  : <span>{props.quantity} x {props.name}</span>
              }
              {!view_only && <span>₹ {props.price}</span>}
              <span className="text-xs text-gray-400 pt-1 pr-2 w-52 md:w-80 lg:w-80 break-words">
                {props.description}
              </span>
            </div>

            {
              !view_only &&
              <div className="flex flex-col justify-end align-middle">
                {props.image ? <img src={props.image} alt={props.name} className="w-20 h-20 mb-1" /> : null}
                <ButtonCounter
                  increamentHandler={props.incrementQuantity}
                  decreamentHandler={props.decrementQuantity}
                  count={props.quantity}
                  isLoading={props.isLoading}
                />
                {props.has_customization ? <span className="text-xs text-center">Customizable</span> : null}
              </div>
            }
            {
              view_only &&
              <div className="flex flex-col justify-center items-center w-16">
                {
                  props.status === 'NEW' &&
                  <>
                    <img src={icons.chef} className="bg-yellow-300 rounded-full w-8 h-8" />
                    <p className="text-xs text-gray-500">Preparing</p>
                  </>
                }
                {
                  props.status === 'READY' &&
                  <>
                    <img src={icons.dish} className="bg-yellow-300 rounded-full w-8 h-8" />
                    <p className="text-xs text-gray-500">Ready</p>
                  </>
                }
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default ItemCard;
