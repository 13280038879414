import React, { createContext, useEffect, useState } from "react";
import * as utils from "../Services/utils";

export const UserContext = createContext();

const UserContextProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [otp, setOtp] = useState(null);

    const login = (userData) => {
        utils.setCustomer(userData);
        setUser(userData);
    };

    const logout = () => {
        utils.clearCustomer();
        utils.clearOrderId();
        setUser(null);
    };

    useEffect(() => {
        const _user = utils.getCustomer();
        setUser(_user);
    }, [])

    return (
        <UserContext.Provider value={{ user, login, logout, otp, setOtp }}>
            {children}
        </UserContext.Provider>
    );

}
export default UserContextProvider;